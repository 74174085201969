/* colors */
$white: #ffffff;
$black: #000000;
$grey: #2F363C;
$red: #D43646;
$lightgrey: #F5F5F5;


html * {
  font-size: 100%;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

body{
  font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  font-feature-settings: "liga", "kern";
  font-weight:  normal;
  color: $grey;
  line-height: 30px;
  max-width: 100%;
  min-height: 100%;
  margin: 0;
}

strong {
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  font-weight:  normal;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}



.liste {
  display: block;
  list-style-type: none;
  margin: 0 0 35px 0;
  padding: 0;
}

.liste li {
  font-size: 21px;
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  color: $red;
  line-height: 35px;
}

.liste li:before {
  content: '– ';
  text-indent: 15px;
  margin-left: -19px;
  padding-right: 5px;
}

h1 {
  font-size: 38px;
  line-height: 42px;
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  font-weight:  normal;
  padding: 0 0 12px 0;
  margin: 0;
  text-transform: uppercase;
}

h2 {
  font-size: 28px;
  line-height: 37px;
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  padding: 0 0 12px 0;
  margin: 0;
  text-transform: uppercase;
}

h3 {
  font-size: 21px;
  line-height: 35px;
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  margin: 0;
  text-transform: uppercase;
}

h4 {
  font-size: 21px;
  line-height: 35px;
  font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  margin: 0;
  text-transform: uppercase;
}

h5 {
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

p {
  font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  margin-bottom: 28px;
  font-size: 21px;
  line-height: 35px;
  text-rendering: optimizeLegibility;
  color: $grey;
}

.dropdown.menu > li > a {
  padding: 0.7rem 0.5rem;
}

p.abstand {
  margin-top: 0;
  margin-bottom: 0;
}

p.no-br {
  margin-bottom: 0;
}

h4.no-br {
  padding: 0;
}

a {
  color: inherit;
}

a:hover {
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  transition: ease all 0.4s;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

.red {
  color: $red;
}

.grey {
  color: $grey;
}

.white {
  color: $white;
}

.text-uppercase {
  text-transform: uppercase;
}

/* --- Hintergrundbild --- */

.background-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 82vh;
  margin-top: 250px;
}

.hero {
  display: block;
  position: relative;
  width: 100%;
}

.hero__image, .tiny-hero__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero--full, .reveal.full {
  min-height: 100vh;
}

.hero__image img, .tiny-hero__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* header large */
header.large {
  top: 0;
  height: 250px;
}

/* header small */

header.small {
  height: 100px;
}

header,
nav,
a,
img,
li {
  transition: .4s all;
  -moz-transition: .4s all;
  -webkit-transition: .4s all;
  -o-transition: .4s all;
}

header {
  position: fixed;
  height: 250px;
  z-index: 9000;
  top: 0;
}

.header__box {
  background-color: $white;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}

header.large .logo__box img {
  width: 430px;
  height: 100%;
}

header.small .logo__box img {
  width: 280px;
  height: 100%;
}

header.large .logo__box {
  display: inline-block;
  position: relative;
  top: 15px;
  left: 0;
}

header.small .logo__box {
  display: inline-block;
  position: relative;
  top: -35px;
  left: 0;
}

header.large .nav__list {
  float: right;
  display: inline-block;
  width: 290px;
  top: 73px;
  margin: 0;
  position: relative;
}

header.small .nav__list {
  float: right;
  display: inline-block;
  width: 290px;
  top: -5px;
  margin: 0;
  position: relative;
}


/* --- Navigation --- */

.nav__container {
  position: relative;
  display: block;
  top: 50px;
  padding-right: 0;
  padding-left: 0;
  max-width: 960px;
  margin: 0 auto;
}


.nav__list li {
  font-family: 'KlavikaWebBasicBoldItalic', sans-serif;
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  &:first-child {
    margin: 0;
  }
}

.nav__list li a {
  color: $red;
  &:hover {
    color: $grey;
    transition: 0.3s ease all;
  }
}


/* --- Accordion --- */


.accordion {
  padding-bottom: 70px;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 0px;
  margin-top: -0.75rem;
  content: '';
  background-image: url(/assets/img/pfeil.svg);
  background-repeat: no-repeat;
  width: 40px;
  height: 18px;
}

.is-active > .accordion-title::before {
  content: '';
  background-image: url("/assets/img/pfeil.svg");
  background-repeat: no-repeat;
  width: 40px;
  height: 18px;
  transform: rotate(180deg);
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -1rem;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 30px 0;
  border: 0;
  border-bottom: 0;
  border-top: 1px solid $grey;
  font-size: 0.75rem;
  line-height: 1;
  color: #2F363C;
}

.accordion-item a:hover {
  background: transparent;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: transparent;
}

.accordion-item a:hover h2 {
  color: $red;
  transition: ease all 0.3s;
}

.accordion-content {
  display: none;
  padding: 0 0 80px 0;
  border: none;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid $grey;
  border-radius: 0 0 0 0;
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid $grey;
}

.accordion h2 {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.accordion .accordion-content .liste {
  margin: 0;
}



#hauptmenu li a.clicked {
  color: $grey;
}

/* --- Sections --- */

.white-section#datenschutz, .white-section#impressum {
  margin-top: 350px;
}

.white-section {
  padding: 0;
  margin-top: -90px;
}

.white-section.middle {
  padding: 130px 0 130px 0;
  margin-top: 0;
}

.white-section.start {
  padding: 130px 0 200px 0;
  margin-top: -100px;
}

.anchor {
  position: relative;
  top: 90px;
}

.section-padding {
  padding: 130px 0 130px 0;
}

.section-padding2 {
  padding: 130px 0 130px 0;
}

.lightgrey-section {
  background-color: $lightgrey;
  padding: 130px 0 130px 0;
}

.grey-section.footer {
  background-color: $grey;
  padding: 130px 0 130px 0;
}

.grey-section {
  background-color: $grey;
  padding: 0;
}

.teambild, .teaminfo {
  padding-bottom: 50px;
}

.section-title {
  padding-bottom: 20px;
}


.footer__menu {
  margin: 35px 0 0 0;
  padding: 0;
  list-style-type: none;
}

footer p a:hover {
  color: $white;
  transition: 0.3s ease all;
  font-family: 'KlavikaWebBasicLightItalic', sans-serif;
}

.footer__menu li {
  display: inline-block;
  margin: 0 15px;
  text-transform: uppercase;
  &:first-child {
    margin: 0 15px 0 0;
  }
  & a:hover {
    color: $white;
    transition: 0.3s ease all;
    font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  }
}



.menu-icon:hover::after {
  background: $grey;
  -webkit-box-shadow: 0 7px 0 $grey, 0 14px 0 $grey;
  box-shadow: 0 7px 0 $grey, 0 14px 0 $grey;
}

.menu-icon::after {
  position: absolute;
  top: 0;
  left: 5px;
  display: block;
  width: 100%;
  height: 2px;
  background: $red;
  -webkit-box-shadow: 0 7px 0 $red, 0 14px 0 $red;
  box-shadow: 0 7px 0 $red, 0 14px 0 $red;
  content: '';
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 35px;
  height: 16px;
  cursor: pointer;
}

.grid-padding-x > .cell.teamcontainer {
  padding-right: 0px;
  padding-left: 15px;
  padding-bottom: 0;
  padding-top: 50px;
}



/* Small only */
@media screen and (max-width: 39.9375em) {

  .title-bar {
    display: block;
    background: transparent;
  }

  .meta_menu {
    display: block;
  }

  .nav__list .meta_menu_down a {
    font-size: 16px;
    font-family: KlavikaWebBasicLightItalic, sans-serif;
    letter-spacing: 0.2px;
  }

  .nav__list .meta_menu {
    margin: 80px 0 0 0;
    display: inline-block;

  }

  .accordion-menu .meta_menu_down a {
    padding: 5px 35px;
  }

  .accordion-title {
    padding: 15px 0;
  }

  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 0px;
    margin-top: -0.5rem;
    content: '';
    background-image: url("/assets/img/pfeil.svg");
    background-repeat: no-repeat;
    width: 28px;
    height: 13px;
  }

  .is-active > .accordion-title::before {
    content: '';
    background-image: url("/assets/img/pfeil.svg");
    background-repeat: no-repeat;
    width: 28px;
    height: 13px;
    transform: rotate(180deg);
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -0.75rem;
  }

  .white-section#datenschutz, .white-section#impressum {
    margin-top: 35px;
  }

  header.large, header.small {
    height: 100px;
    background-color: $white;
    max-width: 100%;
  }

  header.large .logo__box img, header.small .logo__box img {
    width: 230px;
    height: 100%;
  }

  header.large .logo__box, header.small .logo__box {
    display: inline-block;
    position: relative;
    top: 25px;
    left: -5px;
  }

  .nav__list ul {
    position: relative;
    top: 30px;
  }

  header.large .nav__list, header.small .nav__list {
    float: none;
    background-color: $grey;
    display: inline-block;
    width: 100vw;
    height: 100vh;
    top: 100px;
    margin: 0;
    left: 0;
    position: absolute;
  }

  .accordion-menu a {
    padding: 0.5rem 35px;
  }

  .background-wrapper {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin-top: 0px;
  }

  .grid-padding-x > .cell.teamcontainer {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0;
    padding-top: 50px;
  }

  p.abstand {
    margin-top: 0;
    margin-bottom: 0;
  }

  .teaminfo h4 {
    padding: 0;
  }

  .grid-padding-x > .cell {
    padding-right: 35px;
    padding-left: 40px;
  }

  .grid-container:not(.full) > .grid-padding-x {
    margin-right: 0;
    margin-left: -0.625rem;
  }

  .grid-container {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto;
  }

  .teaminfo, .section-title {
    padding-bottom: 20px;
  }

  .teambild {
    padding-bottom: 30px;
  }

  h2 {
    font-size: 21px;
    line-height: 32px;
    padding: 0 0 21px 0;
  }

  h3 {
    font-size: 17px;
    line-height: 28px;
  }

  .nav__list li a {
    color: #D43646;
    font-size: 27px;
    &:hover {
      color: $white;
    }
    &:focus {
      color: $white;
    }
  }

  #hauptmenu li a.clicked {
    color: $white;
  }

  #impressum .liste, #datenschutz .liste {
    margin: 0 0 28px 0;
  }

  h4 {
    font-size: 17px;
    line-height: 28px;
    padding: 0 0 28px 0;
  }

  p {
    font-size: 17px;
    line-height: 28px;
  }

  .liste {
    margin: 0 0 0 0;
  }

  .liste li {
    font-size: 17px;
    line-height: 28px;
  }

  .liste li:before {
    content: '– ';
    text-indent: 15px;
    margin-left: -15px;
    padding-right: 5px;
  }

  .nav__container {
    position: relative;
    display: block;
    top: 0;
    padding-right: 30px;
    padding-left: 30px;
    max-width: 100%;
    margin: 0 auto;
  }


  .background-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 90vh;
  }

  .hero__image {
    height: 460px;
    padding-bottom: 20px;
  }

  .hero__image #claim {
    position: absolute;
    display: inline-block;
    top: 480px;
    padding: 0 25px;
    max-width: 100%;
    z-index: 1;
  }

  .hero__image img {
    object-position: 70% 0;
  }

  #claim h1 {
    font-size: 27px;
    line-height: 32px;
  }

  #claim h3 a {
    color: $grey;
    font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  }

  .anchor {
    position: relative;
    top: 0;
  }

  /* --- Sections --- */

  .white-section .section-padding {
    padding: 0 0 50px 0;
    position: relative;
    top: 50px;
  }

  .section-padding {
    padding: 60px 0 50px 0;
    position: relative;
    top: 50px;
  }

  .section-padding2 {
    padding: 0px 0 50px 0;
    position: relative;
    top: 50px;
  }

  .white-section {
    padding: 80px 0 15px 0;
    position: relative;
    top: 27px;
  }

  .white-section.middle {
    padding: 25px 0 75px 0;
    margin-top: 0;
  }


  .white-section.start {
    padding: 50px 0 50px 0;
    margin-top: -50px;
  }

  .lightgrey-section {
    background-color: $lightgrey;
    padding: 50px 0 50px 0;
  }

  .grey-section.footer {
    background-color: $grey;
    padding: 50px 0 50px 0;
  }

  .grey-section {
    background-color: $grey;
    padding: 0 0 15px 0;
  }

  header.large .title-bar, header.small .title-bar {
    position: relative;
    float: right;
    top: 35px;
    padding: 0;
    background: transparent !important;
    color: #D43646;
    display: inline-block;
    left: 0px;
  }


}

.meta_menu {
  display: none;
}

.title-bar {
  display: none;
  background: transparent;
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

  .grid-padding-x > .cell.teamcontainer {
    padding-right: 0px;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 50px;
  }

  .title-bar {
    display: block;
    background: transparent;
  }

  #hauptmenu li a.clicked {
    color: #ffffff;
  }

  .nav__list .meta_menu_down a {
    font-size: 16px;
    font-family: KlavikaWebBasicLightItalic, sans-serif;
    letter-spacing: 0.2px;
  }

  .nav__list .meta_menu {
    margin: 80px 0 0 -15px;
    display: inline-block;
  }

  .accordion-menu a {
    padding: 0.5rem 35px;
  }

  .accordion-menu .meta_menu_down a {
    padding: 5px 50px;
  }

  .accordion-title {
    padding: 15px 0;
  }

  .meta_menu {
    display: block;
  }

  .white-section#datenschutz, .white-section#impressum {
    margin-top: 150px;
  }

  header.large .logo__box img {
    width: 330px;
    height: 100%;
  }

  header.large {
    height: 180px;
    width: 100%;
  }

  header.small {
    height: 120px;
    width: 100%;
  }

  header.small .logo__box img {
    width: 300px;
    height: 100%;
  }

  header.large .logo__box {
    display: inline-block;
    position: relative;
    top: 55px;
    left: 10px;
  }

  header.small .logo__box {
    display: inline-block;
    position: relative;
    top: 25px;
    left: 10px;
  }

  header.large .nav__list {
    background-color: $grey;
    float: none;
    display: inline-block;
    width: 100vw;
    height: 100vh;
    top: 180px;
    margin: 0;
    left: 0;
    position: absolute;
  }

  header.small .nav__list {
    background-color: $grey;
    float: none;
    display: inline-block;
    width: 100vw;
    height: 100vh;
    top: 120px;
    margin: 0;
    left: 0;
    position: absolute;
  }

  .nav__list ul {
    position: relative;
    top: 30px;
    left: 20px;
  }

  .nav__list li a {
    color: #D43646;
    font-size: 20px;
    &:hover {
      color: $white;
    }
    &:focus {
      color: $white;
    }
  }

  #hauptmenu li a.clicked {
    color: $white;
  }

  header.large .title-bar {
    position: relative;
    float: right;
    top: 71px;
    padding: 0;
    background: transparent !important;
    color: #D43646;
    display: inline-block;
    left: -40px;
  }

  header.small .title-bar {
    position: relative;
    float: right;
    top: 44px;
    padding: 0;
    background: transparent !important;
    color: #D43646;
    display: inline-block;
    left: -40px;
  }

  .background-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 75vh;
    margin-top: 180px;
  }

  .grid-padding-x > .cell {
    padding-right: 50px;
    padding-left: 50px;
  }


  .nav__container {
    position: relative;
    display: block;
    top: 0;
    padding-right: 0;
    padding-left: 30px;
    max-width: 100%;
    margin: 0 auto;
  }

  /* --- Sections --- */

  .white-section {
    padding: 130px 0 130px 0;
    margin-top: -60px;
  }

  .white-section.start {
    padding: 130px 0 130px 0;
    margin-top: -60px;
  }

  .lightgrey-section {
    background-color: $lightgrey;
    padding: 70px 0 70px 0;
  }

  .grey-section.footer {
    background-color: $grey;
    padding: 70px 0 70px 0;
  }

  .grey-section {
    background-color: $grey;
    padding: 0 0 25px 0;
  }

  .anchor {
    position: relative;
    top: 50px;
  }

  .hero__image {
    height: 500px;
    padding-bottom: 20px;
  }

  .hero__image #claim {
    position: absolute;
    display: inline-block;
    top: 515px;
    padding: 0 35px;
    max-width: 100%;
    z-index: 90;
  }

  .section-padding2 {
    padding: 0;
  }

  #claim h3 a {
    color: $grey;
    font-family: 'KlavikaWebBasicLightItalic', sans-serif;
  }

}


/* Medium and up */
@media screen and (min-width: 40em) {



}

/* Large and up */
@media screen and (min-width: 64em) {



  .hero__image #claim {
    position: absolute;
    display: inline-block;
    bottom: 50vh;
    width: 90vw;
    z-index: 1;
  }

  #claim h3 {
    font-family: 'KlavikaWebBasicLightItalic', sans-serif;
    a:hover {
      font-family: 'KlavikaWebBasicLightItalic', sans-serif;
      color: $red;
      transition: ease all 0.3s;
    }
  }


}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {



}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {


}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .grid-padding-x > .cell {
    padding-right: 30px;
    padding-left: 30px;
  }

  .grid-x > .large-10 {
    width: 100%;
  }

  header.large .logo__box {
    display: inline-block;
    position: relative;
    top: 15px;
    left: 5px;
  }

  header.large .nav__list {
    float: right;
    display: inline-block;
    width: 260px;
    top: 110px;
    margin: 0;
    position: relative;
  }


  header.small .nav__list {
    float: right;
    display: inline-block;
    width: 260px;
    top: 8px;
    margin: 0;
    position: relative;
  }

  header.small {
    height: 120px;
  }

  header.small .logo__box {
    display: inline-block;
    position: relative;
    top: -44px;
    left: 0;
  }
}
