/*
- - - > |  @font-face  | < - - -
*/
/* Klavika Web Basic Light Italic */
@font-face {
  font-family: 'KlavikaWebBasicLightItalic';
  src: url('/assets/webfonts/KlavikaWebBasicLightItalic.eot');
  src: url('/assets/webfonts/KlavikaWebBasicLightItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/webfonts/KlavikaWebBasicLightItalic.woff2') format('woff2'),
  url('/assets/webfonts/KlavikaWebBasicLightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*
- - - > |  Font Classes  | < - - -
*/
.KlavikaWebBasicLightItalic {
  font-family: 'KlavikaWebBasicLightItalic';
  font-weight: normal;
  font-style: normal;
}

/*
- - - > |  @font-face  | < - - -
*/
/* Klavika Web Basic Bold Italic */
@font-face {
  font-family: 'KlavikaWebBasicBoldItalic';
  src: url('/assets/webfonts/KlavikaWebBasicBoldItalic.eot');
  src: url('/assets/webfonts/KlavikaWebBasicBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('/assets/webfonts/KlavikaWebBasicBoldItalic.woff2') format('woff2'),
  url('/assets/webfonts/KlavikaWebBasicBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/*
- - - > |  Font Classes  | < - - -
*/
.KlavikaWebBasicBoldItalic {
  font-family: 'KlavikaWebBasicBoldItalic';
  font-weight: normal;
  font-style: normal;
}
